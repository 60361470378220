<template>
<div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

        <!-- Forgot Password v1 -->
        <b-card class="mb-0">
            <b-link class="brand-logo">
                <!-- logo -->
                <b-img fluid :src="resolveLogo()" alt="Logo" style="width: 55%;" />
            </b-link>

            <b-card-title class="mb-1">

                Enter your <strong>Acess Key</strong> 🔑

            </b-card-title>

            <!-- form -->
            <validation-observer ref="simpleRules">
                <b-form class="auth-forgot-password-form mt-2" @submit.prevent="validationForm">
                    <!-- email -->
                    <b-form-group label="Acess Key" label-for="acess-key">
                        <validation-provider #default="{ errors }" name="Key" rules="required|max:6">
                            <cleave id="Acess-Key" v-model="key" class="form-control" :raw="false" :options="keyMask" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>

                    <!-- submit button -->
                    <b-button variant="primary" block type="submit">
                        Send
                    </b-button>
                </b-form>
            </validation-observer>

            <b-card-text class="text-center mt-2">
                <b-link :to="{name:'auth-login-v1'}">
                    <feather-icon icon="ChevronLeftIcon" /> Back to login
                </b-link>
            </b-card-text>

        </b-card>
        <!-- /Forgot Password v1 -->
    </div>
</div>
</template>

<script>
import {
    ValidationProvider,
    ValidationObserver
} from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
    BCard,
    BLink,
    BCardText,
    BCardTitle,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BImg
} from 'bootstrap-vue'
import {
    required,
    email,
    max
} from '@validations'

import Cleave from 'vue-cleave-component'
import axios from "axios";
import useJwt from '@/auth/jwt/useJwt'

import customerSettigns from '@core/utils/customerSettigns'

import store from '@/store'

import systemStoreModule from '@core/layouts/components/app-navbar/systemStoreModule'

import { onUnmounted} from '@vue/composition-api'

export default {
    components: {
        VuexyLogo,
        BCard,
        BLink,
        BCardText,
        BCardTitle,
        BFormGroup,
        BFormInput,
        BButton,
        BForm,
        ValidationProvider,
        ValidationObserver,
        BImg,
        Cleave
    },
    data() {
        return {
            userEmail: '',
            // validation
            required,
            max,
            email,
            key: '',

            keyMask: {
                //prefix: '55',
                //delimiters: ['.', '-'], 
                blocks: [6],
                numericOnly:true
                //uppercase: true,
                //phone: true,
                //phoneRegionCode: 'BR',
            },

            userSettings: null,
            loadingLogo: true
        }
    },
    mounted() {
        if (localStorage.userEmail != undefined) this.userEmail = localStorage.userEmail;

        this.getSetings()

    },
    methods: {

        async getSetings() {
            this.loadingLogo = true
            this.userSettings = await customerSettigns()
            this.loadingLogo = false
        },
        resolveLogo() {

            console.log(`userSettings`, this.userSettings)
            if (!this.userSettings) {

                return require('@/assets/images/logo/LogoExtend.png')
            } else {

                return this.userSettings.logoUrl
            }
        },

        validationForm() {
            this.$refs.simpleRules.validate().then(success => {
                if (success) {

                    axios
                        .post("/JWT/LoginAccessKey", {
                            email: this.userEmail,
                            accessKey: this.key
                        })
                        .then(response => {

                            const {
                                userData
                            } = response.data
                            useJwt.setToken(response.data.accessToken)
                            useJwt.setRefreshToken(response.data.refreshToken)
                            localStorage.setItem('userData', JSON.stringify(userData))
                            this.$ability.update(userData.ability)

                            // ? This is just for demo purpose as well.
                            // ? Because we are showing eCommerce app's cart items count in navbar
                            //this.$store.commit('app-ecommerce/UPDATE_CART_ITEMS_COUNT', userData.extras.eCommerceCartItemsCount)

                            // ? This is just for demo purpose. Don't think CASL is role based in this case, we used role in if condition just for ease
                            //this.$router.replace(getHomeRouteForLoggedInUser(userData.role))
                            this.$router.replace("/")
                                .then(() => {
                                    this.$toast({
                                        component: ToastificationContent,
                                        position: 'top-right',
                                        props: {
                                            title: `Welcome ${userData.fullName || userData.username}`,
                                            icon: 'CoffeeIcon',
                                            variant: 'success',
                                            text: `You have successfully logged in as ${userData.role}.`,
                                        },
                                    })
                                })
                        })
                        .catch(error => {
                            console.log(error)

                            this.$toast({
                                        component: ToastificationContent,
                                        position: 'top-right',
                                        props: {
                                            title: `Fail`,
                                            icon: 'CoffeeIcon',
                                            variant: 'error',
                                            text: `Authentication Failed.`,
                                        },
                                    })
                        })

                }
            })
        },
    },
    setup() {
        const SYSTEM_APP_STORE_MODULE_NAME = 'app-system'

        // Register module
        if (!store.hasModule(SYSTEM_APP_STORE_MODULE_NAME)) store.registerModule(SYSTEM_APP_STORE_MODULE_NAME, systemStoreModule)

        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(SYSTEM_APP_STORE_MODULE_NAME)) store.unregisterModule(SYSTEM_APP_STORE_MODULE_NAME)
        })
    }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
